<template>

  <section  class="uk-section uk-section-secondary uk-section-large">
    <div class="uk-container">

        <div class="no-print uk-margin-medium-top ">

        </div>

            <h1>Nueva Cotización</h1>

            <p>Llena los datos</p>

            <form @submit.prevent="">
                <fieldset class="uk-fieldset">
                    <input type="text" class="uk-input" v-model="cotizacion.nombre" placeholder="Nombre del proyecto">
                    <label >Currency<br></label>
                    <select class="uk-select">
                        <option value="mxn">MXN</option>
                        <option value="usd">USD</option>
                    </select>
                </fieldset>
            </form>

            <hr>


            <h3>Servicios</h3> 

                <p>Agrega servicios</p>

            <form @submit.prevent="addService">
                <div uk-grid class="uk-child-width-1-4">

                    <div>
                        <input type="text" class="uk-input" v-model="servicio.nombre" placeholder="Nombre del servicio">
                    </div>
                    <div>
                        <input type="number" class="uk-input" v-model="servicio.cantidad" placeholder="Unidades del servicio">
                    </div>
                    <div>
                        <input type="number" style="0.01" class="uk-input" v-model="servicio.precio" placeholder="Precio del servicio">
                    </div>
                    <div>
                        <button class="uk-button uk-button-primary uk-width-expand" @click="addService"> <span uk-icon="plus-circle"></span>&nbsp; Agregar servicio</button>
                    </div>

                </div>
            </form>
            <form @submit.prevent="addService">
                <div uk-grid class="uk-child-width-1-4">

                    <div>
                        <label >Agregar sub-item <br></label>
                        <input type="text" class="uk-input" v-model="subitem.nombre" placeholder="Nombre del sub-item">
                    </div>
                    <div>
                        <label ><br></label>
                        <input type="number" class="uk-input" v-model="subitem.cantidad" placeholder="Unidades del sub-item $">
                    </div>
                    <div>
                        <label ><br></label>
                        <input type="number" style="0.01" class="uk-input" v-model="subitem.precio" placeholder="Precio del sub-item $">
                    </div>
                    <div>
                        <label ><br></label>
                        <button class="uk-button uk-button-default" type="button" @click="addSubItem"> <span uk-icon="plus-circle"></span>&nbsp;Agregar sub-item</button>
                    </div>

                </div>
            </form>
            <p >Subitems</p>
                <table  class="uk-table uk-table-small">
                    <tr v-for="item,index in servicio.subitems">
                        <td>{{item.cantidad}}</td>
                        <td>{{item.nombre}}</td>
                        <td>${{item.precio}}</td>
                    </tr>
                </table>

                <hr>
                <h4 >SERIVCIOS</h4>
                <table  class="uk-table uk-table-striped">
                    <tbody>
                        <tr v-for="item,index in cotizacion.servicios">
                            <td class="uk-width-3-6">{{item.nombre}}
                                <p v-if="item.subitems">
                                    <table class="uk-table uk-table-small">
                                        <tr v-for="subitem,subindex in cotizacion.servicios.subitems">
                                            <td>{{subitem.nombre}}</td>
                                            <td>${{subitem.precio}}</td>
                                        </tr>
                                    </table>
                                </p>
                            </td>
                            <td class="uk-width-2-6">${{item.precio}}</td>
                            <td class="uk-width-1-6"><a href="#" @click="delService"><span uk-icon='trash'></span></a></td>
                        </tr>
                    </tbody>
                </table>
    </div>
  </section>

</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
export default {
  setup() {
    const cotizacion = ref({
        servicios : {}
    });
    const logo = require("@/assets/logo.png");


    const servicio = ref({
        subitems : {}
    })
    const addService = ()=>{
        cotizacion.value.servicios[String(new Date().getTime)] = servicio.value
        servicio.value = {
            subitems : {}
        }
    }
    const subitem = ref({})
    const addSubItem = ()=>{
        servicio.value.subitems[String(new Date().getTime)] = subitem.value
        subitem.value = {}
    }
    const delService = (index)=>{
        delete cotizacion.value.servicios[index]
    }

    return {
      cotizacion,
      logo,

      servicio,
      addService,
      delService,
      subitem,
      addSubItem,

    };
  },
};
</script>

<style>
</style>